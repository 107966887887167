import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Img = makeShortcode("Img");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Grid container mdxType="Grid">
  <Grid item xs={12} sm={8} md={9} mdxType="Grid">
    <h1>Effective problem-solving</h1>
    <h3>5. Put the plan into action.</h3>
    <p>Exactly that – put the solution into action.</p>
    <h3>6. Evaluate the outcome.</h3>
    <p>
      <i>So how did your teenager go? Is there progress towards your teenager’s goal?</i>
    </p>
    <p>
      Your teenager may face hiccups or obstacles along the way, and not all solutions will work.{' '}
      <b>Sometimes your teenager may need to try more than one solution.</b>
    </p>
    <p>Discuss with your teenager:</p>
    <ul>
      <li>What has worked well?</li>
      <li>What hasn’t worked so well?</li>
      <li>What could you/we do differently to make the solution work better?</li>
      <li>What could you/we change or improve upon?</li>
    </ul>
  </Grid>
  <Grid xs={12} sm={4} md={3} item mdxType="Grid">
    <Img src="/images/m8/Mod7-p6.svg" alt="recognise" mdxType="Img" />
  </Grid>
    </Grid>
    <p>{`If the solution has not worked, encourage your teenager not to give up, but to try another approach. If necessary, go back to step 3 and choose a new solution or make changes to the existing one.`}</p>
    <p>{`It’s okay for your teenager to feel disappointed. Encourage your teenager to take responsibility for things that go wrong if it’s their fault. The best thing to do is help your teenager learn from their failures and try again.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m8/39.svg" alt="Untie Knots" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      